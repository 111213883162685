import Alpine from "alpinejs";
import flatpickr from "flatpickr";
import dayjs from "dayjs";
import rangePlugin from "flatpickr/dist/plugins/rangePlugin";
import { French } from "flatpickr/dist/l10n/fr.js";
import "../styles/main.scss";
import "flatpickr/dist/flatpickr.min.css";

import "dayjs/locale/fr";
dayjs.locale("fr");

const MAX_ALLOWED_CHILDREN = 4;
const CHILD_PRICE = 300;

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const BASE_API_URL = "https://quaryati.herokuapp.com/api";

async function fetchApi(path, options) {
  options = options || {};
  options.headers = options.headers || {};
  options.headers["Content-Type"] = "application/json";
  const url = `${BASE_API_URL}${path}`;
  const response = await fetch(url, options);
  return response.json();
}

// Flatpickr setup
flatpickr.localize(French);
flatpickr.setDefaults({
  dateFormat: "Y-m-d",
  altInput: true,
  altFormat: "d/m/Y",
  disableMobile: true,
});

window.headerInit = function () {
  return {
    isMenuOpen: false,
    items: [
      {
        text: "A Propos",
        url: "/a-propos",
      },
      {
        text: "Hébergement",
        url: "/hebergement",
      },
      {
        text: "Restaurant",
        url: "/restaurant",
      },
      {
        text: "Spa",
        url: "/spa",
      },
      {
        text: "Yoga",
        url: "/yoga",
      },
      {
        text: "Evénements",
        url: "/evenements",
      },
      {
        text: "Galerie",
        url: "/galerie",
      },
    ],
  };
};

window.formInit = function () {
  return {
    init() {
      // Load rooms data
      this.loadRooms();

      // Initialize date picker
      const that = this;
      flatpickr("#arrival", {
        plugins: [new rangePlugin({ input: "#departure" })],
        onChange(selectedDates) {
          if (selectedDates.length > 1) {
            that.form.days = dayjs(selectedDates[1]).diff(selectedDates[0], "day");
            that.form.arrivalDate = dayjs(selectedDates[0]).format("DD/MM/YYYY");
            that.form.arrival_formatted = dayjs(selectedDates[0]).format("YYYY-MM-DD");
            that.form.arrivalDay = dayjs(selectedDates[0]).format("dddd");
            that.form.departure = dayjs(selectedDates[1]).format("DD/MM/YYYY");
            that.form.departureDay = dayjs(selectedDates[1]).format("dddd");
            that.form.departure_formatted = dayjs(selectedDates[1]).format("YYYY-MM-DD");
            delete that.errors.departure;
          }
        },
      });
    },
    submitted: false,
    submitting: false,
    rooms: [],
    form: {
      days: 0,
      arrival: "",
      arrivalDate: "",
      arrivalDay: "",
      arrival_formatted: "",
      departure: "",
      departureDay: "",
      departure_formatted: "",
      selectedRoomNumber: null,
      adults: 1,
      children: 0,
      childrenAges: [],
      fullname: "",
      email: "",
      phone: "",
      country: "",
      notes: "",
    },
    errors: {},
    reservation: null,
    validate() {
      if (!this.form.arrival) this.errors.arrival = "Veuillez choisir la date d'arrivée";
      if (!this.form.departure) this.errors.departure = "Veuillez choisir une date de départ";
      if (!this.form.selectedRoomNumber) this.errors.selectedRoomNumber = "Veuillez choisir une chambre";
      if (!this.form.fullname) this.errors.fullname = "Veuillez entrer votre nom";
      if (!this.form.email.includes("@")) this.errors.email = "Veuillez saisir votre email";
      if (!this.form.phone) this.errors.phone = "Veuillez entrer votre numéro de téléphone";
      if (!this.form.country) this.errors.country = "Veuillez entrer votre pays";

      if (this.form.childrenAges.length) {
        if (this.form.childrenAges.findIndex((a) => a == -1) != -1)
          this.errors.childrenAges = "Veuillez entrer l'âge de chaque enfant";
      }

      return Object.keys(this.errors).length === 0;
    },

    async onSubmit() {
      if (this.submitting) return;
      if (!this.validate()) return;
      this.submitting = true;
      this.reservation = null;

      const body = JSON.stringify(this.form);

      try {
        const data = await fetchApi("/register", {
          method: "POST",
          body,
        });

        this.reservation = {
          ...data,
          arrival: dayjs(data.arrival).format("DD/MM/YYYY"),
          departure: dayjs(data.departure).format("DD/MM/YYYY"),
          arrivalDay: dayjs(data.arrival).format("dddd"),
          departureDay: dayjs(data.departure).format("dddd"),
          payingChildren: data.paying_children,
          freeChildren: data.free_children,
          totalPrice: data.total_price,
          adultsPrice: data.adults_price,
          childrenPrice: data.children_price,
          highSeason: data.high_season,
        };
      } finally {
        this.submitting = false;

        await sleep(500);

        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }
    },

    onRoomChange() {
      delete this.errors.selectedRoomNumber;
      this.form.adults = 1;
      this.form.children = 0;
      this.form.childrenAges = [];
    },
    addChild() {
      if (this.form.children < this.selectedRoom?.children_cap) {
        this.form.children++;
        this.form.childrenAges.push(-1);
      }
    },
    removeChild() {
      if (this.form.children > 0) {
        this.form.children--;
        this.form.childrenAges.pop();
      }
    },
    get childrenAllowed() {
      return this.form.children < this.selectedRoom?.children_cap;
    },
    get selectedRoom() {
      return this.rooms.find((room) => room.number == this.form.selectedRoomNumber);
    },
    async loadRooms() {
      this.rooms = await fetchApi("/rooms");
    },
  };
};

window.addEventListener("DOMContentLoaded", () => {
  Alpine.start();
});
